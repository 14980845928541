<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="routerPath"></headTitle>
        <van-notify v-model="contentShow" :type="contentType" :style="{ marginTop: equipment ? '7vh' : 0 }">
            <span>{{ content }}</span>
        </van-notify>
        <van-form validate-first @failed="onFailed" @submit="submitForm" ref="form" style="margin-top: 5vh">
            <van-field name="radio" label="是否项目付款" required>
                <template #input>
                    <van-radio-group v-model="queryFrom.additional" direction="horizontal">
                        <van-radio :name="0">项目</van-radio>
                        <van-radio :name="1">非项目</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <div v-if="queryFrom.additional == 0">
                <van-field clickable name="picker" :value="pjMsg" label="项目信息" placeholder="点击选择项目" @click="showA = true"
                    :rules="[{ required: true, message: '项目信息不能为空' }]" required />
                <van-popup v-model="showA" position="bottom">
                    <div style="
              width: 100%;
              box-sizing: border-box;
              padding: 10px;
              display: flex;
              justify-content: space-between;
            ">
                        <div @click="showA = false">取消</div>
                        <div style="color: #576 b95" @click="pjPaymentConfirm">确认</div>
                    </div>
                    <van-search class="search" v-model="pjSearch" placeholder="请输入项目名称" shape="round" show-action
                        @input="onPjSearch" @cancel="onPjCancel" action-text="清空" />
                    <van-picker :columns="pjArrOption" ref="pjPicker" value-key="label" @cancel="showA = false" />
                </van-popup>
            </div>
            <van-field name="radio" label="付款单位（请以发票抬头一致）" required>
                <template #input>
                    <van-radio-group v-model="queryFrom.belong" direction="horizontal">
                        <van-radio :name="1">大美自然</van-radio>
                        <van-radio :name="2">中创科技</van-radio>
                        <van-radio :name="3">广云仓</van-radio>
                        <van-radio :name="4">大丰年</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field label="填写人" :value="userMsg" readonly />
            <van-field clickable name="picker" :value="queryFrom.supplier" label="供应商" placeholder="点击选择费供应商"
                @click="showB = true" :rules="[{ required: true, message: '供应商不能为空' }]" required />
            <van-popup v-model="showB" position="bottom">
                <div style="
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            justify-content: space-between;
          ">
                    <div @click="showB = false">取消</div>
                    <div style="color: #576b95" @click="supplierConfirm">确认</div>
                </div>
                <van-search class="search" v-model="supplierSearch" placeholder="请输入供应商名称" shape="round" show-action
                    @input="onSupplierSearch" @cancel="onSupplierCancel" action-text="清空" />
                <van-picker :columns="supplierArrOption" ref="supplierPicker" value-key="label" @cancel="showB = false" />
            </van-popup>

            <van-field clickable name="picker" :value="queryFrom.paymentName" label="费用类型" placeholder="点击选择费用类型"
                @click="showC = true" :rules="[{ required: true, message: '费用类型不能为空' }]" required />
            <van-popup v-model="showC" position="bottom">
                <van-picker show-toolbar :columns="payOption" value-key="label" @confirm="pyTypeConfirm"
                    @cancel="showC = false" />
            </van-popup>

            <van-field v-model="queryFrom.price" type="number" label="付款金额（元）" placeholder="请输入"
                :rules="[{ required: true, message: '付款金额不能为空' }]" required />

            <van-field v-model="queryFrom.paymentTime" is-link readonly label="付款时间" placeholder="请选择付款时间"
                @click="chooseTime = true" :rules="[{ required: true, message: '付款金额不能为空' }]" required />
            <van-popup v-model="chooseTime" round position="bottom">
                <van-datetime-picker v-model="currentDate" type="date" title="付款时间" @confirm="choosePayTime"
                    @cancel="chooseTime = false" />
            </van-popup>

            <van-field v-model="queryFrom.description" rows="2" autosize label="付款说明" type="textarea" maxlength="800"
                placeholder="请输入付款说明" show-word-limit :rules="[{ required: true, message: '付款说明不能为空' }]" required />
            <van-field name="uploader" label="附件">
                <template #input>
                    <van-uploader v-model="uploader" @click-preview="viewFile" accept="file" upload-icon="plus"
                        :after-read="afterRead" :before-read="beforeRead" @delete="deleteFile"
                        :preview-full-image="false" />
                </template>
            </van-field>

            <process @judgeList="getJudgeList" @copyList="getCopyList" :avatarList="selectList" :copyUserList="copyUserList"
                :routerPath="'/newPjPayment'" :distinguish="belong">
            </process>
            <van-button round block type="info" native-type="submit" :disabled="prohibit || uploading" :loading="isLoading"
                loading-text="加载中...">提交</van-button>
        </van-form>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
    Field,
    Popup,
    DatetimePicker,
    Picker,
    Uploader,
    Button,
    Toast,
    Form,
    Notify,
    Search,
    RadioGroup,
    Radio,
} from "vant";

import { getToken } from "../units/auth";
import { timeChange } from "../units/units";
import { infoList, getInfo } from "../api/info";
import { getConfig, fileUpload, suppliserList } from "../api/api";
import { paymentAdd } from "../api/payment";

Vue.use(Field);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Notify);
Vue.use(Search);
Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);

import process from "../components/process.vue";
export default {
    name: "newPjPayment",
    components: {
        headTitle,
        process,
    },
    data() {
        return {
            title: "新建项目付款",
            pjMsg: "", //项目信息显示文字
            pjOption: [], //项目选项
            pjArrOption: [], //项目存放数组
            pjSearch: "", //项目筛选值
            userMsg: "", //用户显示文字
            showA: false,
            showB: false,
            showC: false,
            chooseTime: false,
            currentDate: new Date(),
            supplierOption: [], //供应商选项
            supplierArrOption: [], //供应商存放数组
            supplierSearch: "", //筛选值
            payOption: [], //费用选项
            queryFrom: {
                projectId: null, //项目id
                belong: 1, //项目归属
                supplier: "", //供应商
                paymentType: "", //付款类型
                paymentName: "", //付款名称
                price: "", //付款金额
                paymentTime: "",
                description: "", //付款说明
                fileList: [], //文件列表
                reviewedList: [], //审核人列表
                copyList: [], //抄送人列表
                additional: 0, //判断是否非项目的报销
            },
            uploader: [], //附件
            uploading: false, //是否正在上传
            belong: null, //项目归属
            selectArr: [], //默认选择审核人数组
            selectList: [], //选择完审核人后的返回
            // flag: [],//装载已选审核人列表
            copyUserArr: [], //抄送人默认列表
            copyUserList: [], //抄送人列表
            clear: false,
            routerPath: "/pjPayment",
            equipment: false, //客户端判断
            contentShow: false, //是否显示提醒
            content: "", //通知内容
            contentType: "warning",
            prohibit: false,
            // powerList: [], //value 1总经理 2财务 3行政
            isLoading: false,
        };
    },
    mounted() {
        // this.powerList = JSON.parse(getToken("powerList") || "[]");
        this.isMobile();
        this.queryFrom.additional = this.$route.query.additional
            ? this.$route.query.additional * 1
            : 0;
        this.title = this.queryFrom.additional == 0 ? "新建项目付款" : "新建付款";
        this.routerPath = this.$route.query.route
            ? this.$route.query.route
            : "/pjPayment";
        this.getSupplierList();
        this.getPjOption();
        this.getPayType();
        this.getToken();
    },
    watch: {
        "queryFrom.additional": {
            handler(newly) {
                if (newly == 1) {
                    this.belong = 3;
                    this.queryFrom.projectId = null;
                    this.pjMsg = "";
                } else {
                    this.belong = this.queryFrom.belong;
                }
                this.getPayType();
                this.queryFrom.paymentName = ""
                this.queryFrom.paymentType = ""
            },
        },
        "queryFrom.belong": {
            handler(newly) {
                if (this.queryFrom.additional == 0) {
                    this.belong = newly;
                }
            },
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.path == "/paymentDetails") {
            next((vm) => {
                if (vm.$route.query.detail) {
                    let obj = JSON.parse(vm.$route.query.detail);
                    delete obj.reviewedList;
                    delete obj.copyList;
                    for (let key in vm.queryFrom) {
                        vm.queryFrom[key] = obj[key];
                    }
                    vm.belong = vm.queryFrom.additional == 0 ? obj.belong : 3;
                    vm.pjMsg = obj.projectName;
                    vm.userMsg = obj.userName;
                    vm.uploader = vm.queryFrom.fileList;
                }
            });
        }
        next();
    },
    activated() {
        this.title = this.queryFrom.additional == 0 ? "新建项目付款" : "新建付款";
        this.getSecectList();
        this.getCopyUserList();
    },
    beforeDestroy() {
        //收尾操作，销毁
        window.localStorage.removeItem("chooseList");
        window.localStorage.removeItem("copyList");
    },
    methods: {
        isMobile() {
            this.equipment = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
                ? false
                : true;
        },
        getToken() {
            this.userMsg = getToken("userName");
        },
        getPjOption() {
            infoList().then((res) => {
                let obj = res.data.rows.map((it) => {
                    // console.log(it.projectName, it.belong)
                    return {
                        label: it.projectName,
                        value: it.id,
                        belong: it.belong,
                    };
                });
                this.pjOption = this.pjArrOption = obj;
            });
        },
        onPjSearch(val) {
            this.pjArrOption = this.pjOption.filter((it) => {
                if (it.label.indexOf(val) != -1) {
                    return it;
                }
            });
        },
        onPjCancel() {
            this.pjArrOption = this.pjOption;
        },
        pjPaymentConfirm() {
            this.showA = false;
            this.queryFrom.projectId = this.$refs.pjPicker.getValues()[0].value;
            this.pjMsg = this.$refs.pjPicker.getValues()[0].label;
            switch (this.$refs.pjPicker.getValues()[0].belong) {
                case "DM":
                    this.queryFrom.belong = 1;
                    this.belong = 1;
                    break;
                case "ZC":
                    this.queryFrom.belong = 2;
                    this.belong = 2;
                    break;
                case 'GYC':
                    this.queryFrom.belong = 3
                    this.belong = 3;
                    break;
                case 'DFN':
                    this.queryFrom.belong = 4
                    this.belong = 4;
                    break;
                default:
                    this.belong = 3;
            }
            getInfo(this.queryFrom.projectId).then((res) => {
                this.contentShow = false;
                this.prohibit = false;
                if (
                    res.data.data.budgetPercent >= 70 &&
                    res.data.data.budgetPercent < 80
                ) {
                    // Notify({ type: 'danger', message: '该项目支出已达到预警值，请注意控制支出' });
                    this.content = "项目准备进入预警状态";
                    this.contentType = "warning";
                    this.contentShow = true;
                } else if (
                    res.data.data.budgetPercent >= 80 &&
                    res.data.data.budgetPercent < 100
                ) {
                    this.content = `已支出费用占成本预算的${res.data.data.budgetPercent}%，请注意控制支出`;
                    this.contentType = "danger";
                    this.contentShow = true;
                } else if (res.data.data.budgetPercent >= 100) {
                    this.content = `支出费用已超出预算，无法付款，请申请增加预算`;
                    this.contentType = "danger";
                    this.contentShow = true;
                    this.prohibit = true;
                }
            });
        },
        choosePayTime() {
            this.queryFrom.paymentTime = timeChange(this.currentDate, true)
            this.chooseTime = false;
        },
        onSupplierSearch(val) {
            this.supplierArrOption = this.supplierOption.filter((it) => {
                if (it.label.indexOf(val) != -1) {
                    return it;
                }
            });
        },
        onSupplierCancel() {
            this.supplierArrOption = this.supplierOption;
        },
        //获取供应商
        getSupplierList() {
            suppliserList().then((res) => {
                let obj = res.data.rows.map((it) => {
                    return {
                        label: it.name,
                        value: it.name,
                    };
                });
                this.supplierOption = this.supplierArrOption = obj;
            });
        },
        supplierConfirm() {
            this.queryFrom.supplier = this.$refs.supplierPicker.getValues()[0].value;
            this.showB = false;
        },
        getPayType() {
            if (this.queryFrom.additional == 1) {
                //非项目类型
                getConfig(9).then((res) => {
                    let obj = res.data.rows.map((it) => {
                        console.log(it.name);
                        return {
                            label: it.name,
                            value: it.value * 1,
                        };
                    });
                    this.payOption = obj;
                });
            } else {
                getConfig(4).then((res) => {
                    let obj = res.data.rows.map((it) => {
                        console.log(it.name);
                        return {
                            label: it.name,
                            value: it.value * 1,
                        };
                    });
                    this.payOption = obj;
                });
            }
        },
        pyTypeConfirm(val) {
            this.showC = false;
            this.queryFrom.paymentType = val.value;
            this.queryFrom.paymentName = val.label;
        },
        beforeRead(file) {
            if (file.type == 'application/msword') {
                Toast('请将.doc类型文件另存为改成.docx类型的文件后再进行上传（请注意:手动改后缀可能文件类型并没有变化）')
                return
            }
            //上传之前校验
            if (file.size > 30 * 1024 * 1024) {
                Toast("只允许上传30M的文件");
                return false;
            }
            return true;
        },
        async afterRead(file) {
            //文件读取完成后的回调函数
            file.status = "uploading";
            file.message = "上传中...";
            this.uploading = true;
            let fd = new FormData();
            fd.append("file", file.file);
            await fileUpload(fd)
                .then((res) => {
                    if (res.data.code == 200) {
                        let obj = {
                            fileName: res.data.originalName,
                            url: res.data.url,
                        };
                        this.uploader[this.uploader.length - 1].url = res.data.url; //给返回的文件添加url属性
                        this.uploader[this.uploader.length - 1].fileName =
                            res.data.originalName;
                        this.queryFrom.fileList.push(obj);
                        file.status = "";
                        file.message = ""; //上传状态
                        this.uploading = false;
                    } else {
                        file.status = "failed";
                        file.message = "上传失败";
                        this.uploading = false;
                    }
                })
                .catch((err) => {
                    if (err) {
                        file.status = "failed";
                        file.message = "上传失败";
                        this.uploading = false;
                    }
                });
        },
        deleteFile(file) {
            this.queryFrom.fileList = this.queryFrom.fileList.filter((it) => {
                return it.url !== file.url;
            });
        },
        viewFile(file) {
            console.log(file);
            if (
                file.url.indexOf(".jpg") !== -1 ||
                file.url.indexOf(".jpeg") !== -1 ||
                file.url.indexOf(".png") !== -1
            ) {
                return;
            }
            this.$router.push({
                path: "/preview",
                query: { url: file.url, routerPath: "/newPjPayment" },
            });
        },
        //默认审核人流程
        getJudgeList(val) {
            this.selectArr = val;
        },
        //获取已选人的列表
        getSecectList() {
            this.selectList =
                JSON.parse(window.localStorage.getItem("chooseList")) || [];
        },
        getCopyList(val) {
            this.copyUserArr = val;
        },
        getCopyUserList() {
            this.copyUserList =
                JSON.parse(window.localStorage.getItem("copyList")) || [];
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message);
            let selectAll = JSON.parse(JSON.stringify(this.selectList))
                .concat(this.selectArr)
            console.log(selectAll)
        },
        submitForm() {
            this.isLoading = true;
            let selectAll = JSON.parse(JSON.stringify(this.selectList))
                .concat(this.selectArr)
            .reverse();
            this.queryFrom.reviewedList = selectAll.map((it, idx) => {
                return {
                    approvalSequence: idx,
                    userId: it.userId || it.commonValue,
                };
            });
            let copyAll = JSON.parse(JSON.stringify(this.copyUserList))
                .concat(this.copyUserArr)
                .reverse();
            this.queryFrom.copyList = copyAll.map((it) => {
                return {
                    userId: it.userId || it.commonValue,
                };
            });
            paymentAdd(this.queryFrom)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.isLoading = false;
                        Toast.success("提交成功");
                        this.$router.replace(this.routerPath);
                        //this.$destroy()
                    }
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 2vh;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;
    font-size: 1rem;
}

/deep/ .van-cell__title {
    -webkit-box-flex: unset;
    -webkit-flex: unset;
    flex: unset;
    text-align: left;
}

/deep/ .van-cell__value {
    text-align: left;
}

.search /deep/ .van-cell {
    margin-bottom: unset;
}
</style>